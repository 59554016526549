// This module provides a means to play and pause iframes for Youtube and Vimeo videos using
// postMessage. Does not currently supprt native <video> elements. For more info see
// https://www.michaelsmyth.co.uk/pausing-youtube-vimeo-videos-postmessage/

/**
 * @param {HTMLIFrameElement}
 */
function postMessage(elem, command) {
  elem.contentWindow.postMessage(JSON.stringify(command), '*');
}

/**
 * @param {HTMLIFrameElement|HTMLVideoElement}
 */
export function playVideo(elem, vendor = elem.dataset.vendor) {
  if (vendor === 'youtube') {
    postMessage(elem, {
      event: 'command',
      func: 'playVideo',
    });
  } else if (vendor === 'vimeo') {
    postMessage(elem, {
      method: 'play',
      value: 'true',
    });
  } else {
    console.error(`playVideo: Invalid vendor '${vendor}' for iframe`, elem);
  }
}

/**
 * @param {HTMLIFrameElement}
 */
export function pauseVideo(elem, vendor = elem.dataset.vendor) {
  if (vendor === 'youtube') {
    postMessage(elem, {
      event: 'command',
      func: 'pauseVideo',
    });
  } else if (vendor === 'vimeo') {
    postMessage(elem, {
      method: 'pause',
      value: 'true',
    });
  } else {
    console.error(`pauseVideo: Invalid vendor '${vendor}' for iframe`, elem);
  }
}
