//Update CSRF
export default function init() {
  document.addEventListener('DOMContentLoaded', function () {
    const $forms = [...document.querySelectorAll('.c-form')];
    $forms.forEach(($form) => {
      // Fetch the form we want to deal with
      const datasetFuiForm = JSON.parse($form.dataset.fuiForm);
      const { formHandle } = datasetFuiForm;
      // Find the CSRF token hidden input, so we can replace it
      let $csrfInput = $form.querySelector('input[name="CRAFT_CSRF_TOKEN"]');
      // Fetch the new tokens for the form and replace the captcha inputs
      fetch(`/actions/formie/forms/refresh-tokens?form=${formHandle}`)
        .then(result => {
          return result.json();
        })
        .then(result => {
          // Update the CSRF token and captchas.
          // Use `csrf.input` for convenience
          $csrfInput.outerHTML = result.csrf.input;
          // Find the JavaScript captcha hidden input, so we can update it
          if (result.captchas && result.captchas.javascript) {
            // JavaScript captcha
            const jsCaptcha = result.captchas.javascript;
            const $jsCaptchaInput = $form.querySelector(`input[name="${jsCaptcha.sessionKey}"]`);
            if ($jsCaptchaInput) {
              $jsCaptchaInput.value = jsCaptcha.value;
            }
          }
          // Find the Duplicate captcha hidden input, so we can update it
          if (result.captchas && result.captchas.duplicate) {
            // Duplicate captcha
            const duplicateCaptcha = result.captchas.duplicate;
            const $duplicateCaptchaInput = $form.querySelector(`input[name="${duplicateCaptcha.sessionKey}"]`);
            if ($duplicateCaptchaInput) {
              $duplicateCaptchaInput.value = duplicateCaptcha.value;
            }
          }
          // Update the form's hash (if using Formie's themed JS)
          if ($form.form && $form.form.formTheme) {
            $form.form.formTheme.updateFormHash();
          }
        });
    });
  });
}
