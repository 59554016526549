export default function init() {
  let isFormieScriptLoaded = false;
  let formieInstance = null;
  let initialisedFormies = [];
  const loadFormieScript = async () => {
    if (isFormieScriptLoaded) return;

    const { Formie } = await import('../../../../cms/vendor/verbb/formie/src/web/assets/frontend/src/js/formie-lib');
    formieInstance = new Formie();

    isFormieScriptLoaded = true;
  };

  let trackingParams = {};
  const forms = [...document.querySelectorAll('.c-form')];
  forms.forEach((form) => {
    // Load Formie script whenever a given form is visible
    const observer = new IntersectionObserver(entries => {
      entries.forEach(async entry => {
        if (!entry.isIntersecting) return;

        await loadFormieScript();

        if (!initialisedFormies.includes(form)) {
          formieInstance.initForm(form);
          initialisedFormies.push(form);
        }
      });
    });
    observer.observe(form);

    // Form submission
    const submitButton = form.querySelector('button[type="submit"]');
    const datasetFuiForm = JSON.parse(form.dataset.fuiForm);
    const { formHandle } = datasetFuiForm;
    form.addEventListener('onFormieCaptchaValidate', () => console.log('onFormieCaptchaValidate'));
    form.addEventListener('onFormieValidate', () => console.log('onFormieValidate'));
    form.addEventListener('onAfterFormieValidate', () => console.log('onAfterFormieValidate'));
    // Form submit
    form.addEventListener('onBeforeFormieSubmit', () => {
      console.log('onBeforeFormieSubmit', form);
      trackingParams = {
        ...trackingParams,
        event: 'formSubmission',
        formHandle,
      };
      // Where enquiry type radios exist, set the enquiryType value
      const enquiryTypeRadioButtons = [...form.querySelectorAll('input[name="fields[enquiryType]"]')];
      if (enquiryTypeRadioButtons.length) {
        const enquiryTypeElement = enquiryTypeRadioButtons.filter((input) => input.checked)[0];
        if (enquiryTypeElement) {
          const enquiryType = enquiryTypeElement.value;
          trackingParams = {
            ...trackingParams,
            enquiryType,
          };
        }
      }
      // Where hidden enquiry type exists, set the enquiryType value
      const hiddenEnquiryType = form.querySelector('input[name="fields[hiddenEnquiryType]"]');
      if (hiddenEnquiryType) {
        trackingParams = {
          ...trackingParams,
          enquiryType: hiddenEnquiryType.value,
        };
      }
      // Where parks dropdown exists, set the areaOfIntrest value & parks value
      const parksEl = form.querySelector('select[name="fields[parks]"]');
      if (parksEl) {
        const parks = parksEl.value;
        const selectedOption = [...parksEl.options]
          .filter((option) => parks === option.value)[0];
        const areaOfInterest = selectedOption.textContent;
        trackingParams = {
          ...trackingParams,
          areaOfInterest,
          parks,
        };
      }

      const parkNames = form.querySelector('input[name="fields[hiddenParkName]"]');
      if (parkNames) {
        trackingParams = {
          ...trackingParams,
          parks: parkNames.value,
        };
      }

      // Populate Ringside fields
      if (window.ringside) {
        // Visit ID
        const visitId = form.querySelector('input[name="fields[ringsideVisitId]"]');
        if (visitId) {
          visitId.value = window.ringside.rsc_visit_id;
        }

        // User ID
        const userId = form.querySelector('input[name="fields[ringsideUserId]"]');
        if (userId) {
          userId.value = window.ringside.rsc_user_id;
        }
      }

      console.log('pre return true');
      return true;
    });

    // Failed ajax submit
    form.addEventListener('onFormieSubmitError', function (e) {
      console.log('onFormieSubmitError', form, e);
      // re-enable the submit button
      submitButton.disabled = false;
      setTimeout(() => {
        // Scroll to errors
        const errorList = form.querySelector('.fui-alert-error');
        if (errorList) {
          errorList.tabIndex = '-1';
          errorList.focus();
        }
      }, 200);
    });

    // Do something on a successful ajax submit
    form.addEventListener('onAfterFormieSubmit', function (event) {
      console.log('onAfterFormieSubmit', form);

      const { detail } = event;
      if (detail.nextPageId) return;

      // Overwrite the success message since we're going to use it separately on the success page
      // after redirecting
      detail.submitActionMessage = 'Processing...';

      // push to facebook
      const eventId = `Contact-${formHandle}-${detail.submissionId}`;
      trackingParams = {
        ...trackingParams,
        eventId,
      };
      // Remove token from tracking params if it exists
      if ('token' in trackingParams) {
        delete trackingParams.token
      }

      // If return URL is set, redirect, if you do want to redirect to the homepage
      // this will need to be changed. Setting a blank returnUrl in backend will return as "/"
      const returnUrl = new URL(window.location.pathname, window.location.origin);
      const {searchParams} = returnUrl;
      searchParams.set('success', formHandle);
      for (const prop in trackingParams) {
        if (Object.prototype.hasOwnProperty.call(trackingParams, prop)) {
          searchParams.set(prop, trackingParams[prop]);
        }
      }
      if (detail.redirectUrl !== '/') {
        window.location.replace(returnUrl.href);
      }
    });
  })
}
