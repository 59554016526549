/* global Cookiebot */
// Feature
import '@styles/components/c-modal.scss';
import '@styles/layouts/l-modal-newsletter.scss';

import { playVideo, pauseVideo } from '@modules/videoControls';
import MicroModal from 'micromodal';
import Cookies from 'js-cookie';

const modalDelay = 10000; // ms delay before showing the modal
const shownExpires = 365; // days before showing same modal again


export function getModalOptions() {
  return {
    openClass: 'is-open',
    disableScroll: true,
    onShow(modal, trigger) {
      // Play any videos
      /** @param {HTMLIFrameElement} */
      modal.querySelectorAll('.js-video-iframe--autoplay').forEach((i) => playVideo(i));

      if (!trigger || trigger === document.body) {
        // eg. When triggered programmatically (although we get around this with triggerEl.focus( ))
        return;
      }

      // Hide the newsletter trigger
      if (trigger.classList.contains('js-open-modal--newsletter-popup')) {
        trigger.setAttribute('aria-hidden', 'true');
      }
    },
    onClose(modal, trigger) {
      // Pause any videos (iframe[src*="youtube"] for compatibility with @vue-instances/resultsList)
      /** @param {HTMLIFrameElement} */
      modal.querySelectorAll('iframe[src*="youtube"], .js-video-iframe').forEach((i) => {
        const vendor = i.classList.contains('js-video-iframe') ? i.dataset.vendor : 'youtube';
        pauseVideo(i, vendor);
      });

      if (!trigger || trigger === document.body) {
        // eg. When triggered programmatically (although we get around this with triggerEl.focus())
        return;
      }

      // Mark the modal as shown
      if (!trigger.classList.contains('always-show')) {
        markAsShown(modal.id);
      }

      // Show the newsletter trigger
      if (trigger.classList.contains('js-open-modal--newsletter-popup')) {
        trigger.setAttribute('aria-hidden', 'false');
      }
    }
  };
}

const modalDefaults = getModalOptions();

/**
 * @param {string} modalId
 */
function markAsShown(modalId) {
  Cookies.set(modalId, 1, { expires: shownExpires });
}

/**
 * @param {string} modalId
 */
function wasShown(modalId) {
  return Cookies.get(modalId);
}

/**
 * @param {HTMLElement} modal
 */
function registerFormEvents(modal) {
  modal.querySelectorAll('.fui-form').forEach((form) => {
    form.addEventListener('onAfterFormieSubmit', (e) => {
      if (e.detail.nextPageId) {
        return;
      }

      markAsShown(modal.id);
    });
  });
}

/**
 * Shows a modal, only used on page load for .js-open-modal
 *
 * @param {HTMLElement} micromodalTrigger
 */
function showModalForTrigger(triggerEl) {
  // Get the modal options
  const modalId = triggerEl.dataset.micromodalTrigger;

  // Mark the modal as shown when its form is submitted
  if (!triggerEl.classList.contains('always-show')) {
    const modal = document.getElementById(modalId);
    registerFormEvents(modal);
  }

  // Focus the trigger so that MicroModal will pass it to onShow/onClose
  const showModal = () => {
    triggerEl.focus();
    MicroModal.show(modalId, modalDefaults);
  };

  // Show the modal
  if (triggerEl.classList.contains('with-delay')) {
    setTimeout(showModal, modalDelay);
  } else {
    // Used if the user has navigated to a page and expects the popup to be visible immediately
    showModal();
  }
}

export default function init() {
  MicroModal.init(modalDefaults);
  const modalTrigger = document.querySelector('.js-open-modal');

  // Show a modal on page load
  if (modalTrigger) {
    const modalId = modalTrigger.dataset.micromodalTrigger;
    if (modalTrigger.classList.contains('always-show')) {
      // Cookies N/A if we always show
      showModalForTrigger(modalTrigger);
    } else if (!wasShown(modalId)) {
      if (window.Cookiebot && !Cookiebot.hasResponse) {
        // Wait for the cookies response
        window.addEventListener('CookiebotOnAccept', () => {
          if (Cookiebot.consent.marketing) {
            showModalForTrigger(modalTrigger);
          }
        });
      } else if (!window.Cookiebot
        // window.Cookiebot may exist as an HTML element
        || (Cookiebot.consent && Cookiebot.consent.marketing)
      ) {
        showModalForTrigger(modalTrigger);
      } // Else declined cookies - don't show
    }
  }

  return MicroModal;
}
