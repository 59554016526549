// Utilities
import '@styles/utilities/u-screen-reader.scss';

import backLink from '@modules/backLink';
import formie from '@modules/formie';
import formieRefresh from '@modules/formieRefresh';
import 'focus-visible';
import modal from '@modules/modal';

backLink();
formieRefresh();
formie();
modal();
